<template>
  <div class="second-layout">
    <HeaderDashboard />
    <slot />
    <Footer />
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import Footer from '@/components/molecules/Footer.vue'
import HeaderDashboard from '@/components/sections/HeaderDashboard.vue'

export default defineComponent({
  name: 'SecondLayout',
  components: {
    Footer,
    HeaderDashboard
  }
})
</script>

<style lang="scss" scoped>
.second-layout {
}

@media (max-width: $break-md) {
  .second-layout {
  }
}
</style>
